<template>
    <div class="grid grid-cols-12 items-center">
        <div class="col-span-1 p-2">
            If
        </div>
        <div class="col-span-3 p-2">
            <select id="useAllConditions" v-model="calculation.uses_all_conditions" name="usesAllConditions" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                <option value="false">Any</option>
                <option value="true">All</option>
            </select>
        </div>
        <div class="col-span-7 p-2">
            of the following conditions are met:
        </div>
        <div class="col-span-1 p-2">
            <button @click="newCondition" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Add Condition
                <!-- <EnvelopeIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" /> -->
            </button>
        </div>
        <div class="col-span-12">
            <div v-for="condition, index in calculation.pricing_conditions" class="my-2 p-2 border border-2 border-dashed">
                <div class="grid grid-cols-12 space-x-2 items-center">
                    <input type="hidden" v-model="condition.id" />
                    <div class="col-span-3">
                        <select v-model="condition.data_point_id" id="dataPointId" @change="checkifConditionHasDropdown(condition); checkIfConditionIsDateRange(condition);" name="dataPointId" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="select data">
                            <option></option>
                            <option v-for="dataPoint in conditionDataPoints" :value='dataPoint.id'>{{dataPoint.name}}</option>
                        </select>
                    </div>
                    <span class="inline-block col-span-1 mx-2 text-center">Is</span>
                    <div class="col-span-2">
                        <select v-model="condition.operator" id="operator" @change="checkifConditionHasTwoValues(condition); checkIfConditionComparesDataPoint(condition);" name="operator" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option></option>
                            <option value="equal to" selected="">Equal to</option>
                            <option value="not equal to">Not Equal to</option>
                            <option v-if="! condition.hasDropdown" value="greater than">Greater than</option>
                            <option v-if="! condition.hasDropdown" value="greater or equal">Greater or Equal</option>
                            <option v-if="! condition.hasDropdown" value="less than">Less than</option>
                            <option v-if="! condition.hasDropdown" value="less or equal">Less or Equal</option>
                            <option v-if="! condition.hasDropdown" value="between">Between</option>
                            <option v-if="! condition.hasDropdown && ! condition.isDateRange" value="equal to data">Equal to Data Point</option>
                            <option v-if="! condition.hasDropdown && ! condition.isDateRange" value="greater than data">Greater than Data Point</option>
                            <option v-if="! condition.hasDropdown && ! condition.isDateRange" value="greater or equal data">Greater or Equal Data Point</option>
                            <option v-if="! condition.hasDropdown && ! condition.isDateRange" value="less than data">Less than Data Point</option>
                            <option v-if="! condition.hasDropdown && ! condition.isDateRange" value="less or equal data">Less or Equal Data Point</option>
                        </select>
                    </div>
                    <div v-if="! condition.hasTwoValues" class="col-span-5">
                        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <div v-if="condition.hasDropdown">
                                <select v-model="condition.value_1" name="value1" id="value1" class="block w-full border-0 py-0 pl-1 pr-10 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" >
                                <option></option>
                                <option v-for="(value, id) in condition.dropdownList" :value='id'>{{value}}</option>
                                </select>
                            </div>
                            <div v-else-if="condition.isDateRange">
                                <Datepicker
                                    v-model="condition.value_1"
                                    name="value1"
                                    id="value1"
                                    clearable="true"
                                    inputFormat="yyyy-MM-dd"
                                    placeholder="YYYY-MM-DD"
                                    class="inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
                                    <template
                                        v-slot:clear="{ onClear }"
                                    >
                                        <div class="absolute inline-block left-2">
                                            <button @click="onClear">x</button>
                                        </div>
                                    </template>
                                </Datepicker>
                            </div>
                            <div v-else-if="condition.isDataPointCompare">
                                <select v-model="condition.data_point_id_2" name="value1" id="value1" class="block w-full border-0 py-0 pl-1 pr-10 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" >
                                    <option></option>
                                    <option v-for="dataPoint in conditionDataPoints.filter(conditionDataPoint => ! this.dropdownLists[conditionDataPoint.id])" :value='dataPoint.id'>{{dataPoint.name}}</option>
                        </select>
                            </div>
                            <div v-else>
                                <input v-model="condition.value_1" type="text" name="value1" id="value1" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" />
                            </div>
                        </div>
                    </div>
                    <div v-if="condition.hasTwoValues" class="col-span-2">
                        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <div v-if="condition.hasDropdown">
                                <select v-model="condition.value_1" name="value1" id="value1" class="block w-full border-0 py-0 pl-1 pr-10 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" >
                                    <!-- mt-1 block w-full border-0 py-0 pl-3 pr-10 focus:ring-indigo-500 sm:text-sm -->
                                <option></option>
                                <option v-for="(value, id) in condition.dropdownList" :value='id'>{{value}}</option>
                                </select>
                            </div>
                            <div v-else>
                                <div v-if="condition.isDateRange">
                                    <Datepicker
                                    v-model="condition.value_1"
                                    name="value1"
                                    id="value1"
                                    clearable="true"
                                    inputFormat="yyyy-MM-dd"
                                    placeholder="YYYY-MM-DD"
                                    class="inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
                                    <template
                                    v-slot:clear="{ onClear }"
                                    >
                                    <div class="absolute inline-block left-2">
                                        <button @click="onClear">x</button>
                                    </div>
                                    </template>
                                    </Datepicker>
                                </div>
                                <div v-else>
                                <input v-model="condition.value_1" type="text" name="value1" id="value1" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span v-show="condition.hasTwoValues" class="inline-block col-span-1 mx-2 text-center">Through</span>
                    <div v-show="condition.hasTwoValues" class="col-span-2">
                        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <div v-if="condition.isDateRange">
                                <Datepicker
                                v-model="condition.value_2"
                                name="value2"
                                id="value2"
                                clearable="true"
                                inputFormat="yyyy-MM-dd"
                                placeholder="YYYY-MM-DD"
                                class="inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
                                <template
                                v-slot:clear="{ onClear }"
                                >
                                <div class="absolute inline-block left-2">
                                    <button @click="onClear">x</button>
                                </div>
                                </template>
                                </Datepicker>
                            </div>
                            <div v-else>
                                <input v-model="condition.value_2" type="text" name="value2" id="value2" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1 col-end-13 p-2">
                        <button @click="calculation.pricing_conditions.splice(index,1)" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-11 p-2">
            Do the following actions:
        </div>
        <div class="col-span-1 p-2">
            <button @click="newAction" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Add Action
                <!-- <EnvelopeIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" /> -->
            </button>
        </div>
        <div class="col-span-12">
            <div v-for="action, index in calculation.pricing_actions" class="my-2 p-2 border border-2 border-dashed">
                <div class="grid grid-cols-12 space-x-2 items-center">
                    <input type="hidden" v-model="action.id" />
                    <div class="col-span-3">
                        <select v-model="action.data_point_id_1" @change="checkifActionHasDropdown(action);" id="dataPointId1" name="dataPointId1" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="select data">
                            <option></option>
                            <option v-for="dataPoint in actionDataPoints" :value='dataPoint.id'>{{dataPoint.name}}</option>
                        </select>
                    </div>
                    <span class="inline-block col-span-1 mx-2 text-center">=</span>
                    <div class="col-span-3">
                        <select v-if="action.hasDropdown" v-model="action.data_point_id_1" id="dataPointId2" name="dataPointId2" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="select data">
                            <option :value='action.data_point_id_1'> {{actionDataPoints.filter(obj => {return obj.id === action.data_point_id_1})[0].name}}</option>
                        </select>
                        <select v-else v-model="action.data_point_id_2" id="dataPointId2" name="dataPointId2" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="select data">
                            <option></option>
                            <option v-for="dataPoint in actionDataPoints" :value='dataPoint.id'>{{dataPoint.name}}</option>
                        </select>
                    </div>
                    <div class="col-span-1">
                        <select v-model="action.operator" id="operator" name="operator" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option v-if="! action.hasDropdown">*</option>
                            <option v-if="! action.hasDropdown" >+</option>
                            <option v-if="! action.hasDropdown">/</option>
                            <option v-if="! action.hasDropdown">-</option>
                            <option v-if="! action.hasDropdown">round</option>
                            <option>=</option>
                        </select>
                    </div>
                    <div class="col-span-3">
                        <div v-if="action.hasDropdown" class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <select v-model="action.value" name="value1" id="value1" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" >
                                <option></option>
                                <option v-for="(value, id) in action.dropdownList" :value='id'>{{value}}</option>
                            </select>
                        </div>
                        <div v-else class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <input v-model="action.value" type="text" name="value1" id="value1" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="value" />
                        </div>
                    </div>
                    <div class="col-span-1 col-end-13 p-2">
                        <button @click="calculation.pricing_actions.splice(index,1)" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 col-span-12 flex place-content-end space-x-4 items-center">
            <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                    <input v-model="calculation.should_stop_calculations" id="stops_rules" aria-describedby="stopsRules-description" name="stops_rules" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                </div>
                <div class="ml-3 text-sm">
                    <span id="stopsRules-description" class="text-gray-500">Do not process additional rate calculations</span>
                </div>
            </div>
            <button @click="this.$emit('calculationCancel')" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Cancel
            </button>
            <button @click="calculationSave(calculation)" type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Ok
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'dataPoints',
        'pricingRule',
        'initCalculation',
        'dropdownLists',
    ],
    data: function() {
        let calculation = {...this.initCalculation};
        if (calculation.pricing_actions) {
            for (let key in calculation.pricing_actions){
                let action = calculation.pricing_actions[key];
                this.checkifActionHasDropdown(action);
            }
            calculation.pricing_actions = [...this.initCalculation.pricing_actions];
        }
        if (calculation.pricing_conditions) {
            calculation.pricing_conditions = [...this.initCalculation.pricing_conditions];
            for (let key in calculation.pricing_conditions) {
                let condition = calculation.pricing_conditions[key];
                this.checkifConditionHasDropdown(condition);
                condition.hasTwoValues = (condition.operator === 'between');
                condition.isDateRange = false;
                condition.isDataPointCompare = Boolean(condition.pricing_data_point2);
                if (['DateRangeDataPoint','PostingStartDataPoint'].includes(this.findConditionClassFromId(condition.data_point_id))) {
                    condition.isDateRange = true;
                    condition.value_1 = new Date(condition.value_1.replace(/-/g, '\/'));
                    if (condition.hasTwoValues) {
                        condition.value_2 = new Date(condition.value_2.replace(/-/g, '\/'));
                    }
                }
            }
        }
        return {calculation: calculation};
    },
    computed: {
        actionDataPoints() {
            return this.dataPoints.filter(dataPoint => dataPoint.type == 'action');
        },
        conditionDataPoints() {
            return this.dataPoints.filter(dataPoint => dataPoint.type == 'condition');
        },
    },
    methods: {
        newAction: function() {
            this.calculation.pricing_actions.push({
                dataPoint1: '',
                dataPoint2: '',
                operator: '',
                value: '',
            });
        },
        newCondition: function() {
            this.calculation.pricing_conditions.push({
                dataPoint: '',
                operator: '',
                value1: '',
                value2: '',
                isActive: 0,
                hasTwoValues: false,
            });
        },
        findConditionClassFromId: function(id) {
            return this.dataPoints.filter(dataPoint => dataPoint.type == 'condition').filter(function(item) {
                return item.id === id;
            }, id)[0].class;
        },
        checkIfConditionComparesDataPoint: function(condition) {
            if (condition.operator.includes('data')) {
                condition.isDataPointCompare = true;
                condition.value_1 = '';
                condition.value2 = '';
                condition.value_2 = '';
            } else {
                condition.isDataPointCompare = false;
                condition.data_point_id_2 = '';
            }
        },
        checkifConditionHasTwoValues: function(condition) {
            condition.value2 = '';
            condition.hasTwoValues = (condition.operator == 'between');
        },
        checkifConditionHasDropdown: function(condition) {
            if (this.dropdownLists[condition.data_point_id]) {
                condition.hasTwoValues = false;
                condition.hasDropdown = true;
                condition.value2 = '';
                condition.dropdownList = this.dropdownLists[condition.data_point_id];
                condition.data_point_id_2 = '';
            } else if (condition.hasDropdown === true) {
                condition.hasDropdown = false;
                condition.operator = '';
                condition.value_1 = '';
                condition.value2 = ''
                condition.data_point_id_2 = '';
                delete condition.dropdownList;
            }
        },
        checkifActionHasDropdown: function(action) {
            if (this.dropdownLists[action.data_point_id_1]) {
                action.data_point_id_2 = action.data_point_id_1
                action.hasDropdown = true;
                action.operator = '=';
                action.dropdownList = this.dropdownLists[action.data_point_id_1];
            } else if (action.hasDropdown === true) {
                action.hasDropdown = false;
                action.data_point_id_2 = '';
                action.operator = '';
                delete action.dropdownList;
            }
        },
        checkIfConditionIsDateRange: function(condition) {
            if (['DateRangeDataPoint','PostingStartDataPoint'].includes(this.findConditionClassFromId(condition.data_point_id))) {
                condition.isDateRange = true;
            } else {
                condition.isDateRange = false;
            }
        },
        calculationSave: function(calculation) {
            if (this.pricingRule.id === undefined) {
                //Same check exists in PricingRule.vue when trying to add a calculation
                toast('notice', 'Please create/save rule before adding calculations.');
                return;
            }

            let request = {
                 'method': 'post',
                 'url': '/pricingCalculations',
                 'data': this.calculation
            };
            if (this.calculation.id) {
                request.method = 'put';
                request.url = `/pricingCalculations/${this.calculation.id}`;
            }
             axios(request)
            .then(response => {
                //Response not returning
                let responseMessage = response.data.message;
                let tempMessage = 'Calculation Saved!'
                toast('success', tempMessage);
                this.$emit('calculationSave', response.data.calculation);
            })
            .catch(error => {
                let errorMessage = error.response.data.message
                toast('warning', error.response.data.message);
            });
        },
    },
    mounted: function() {
        if (! this.calculation.id) {
            this.calculation = {
                id: null,
                pricing_rule_id: this.pricingRule.id,
                uses_all_conditions: false,
                should_stop_calculations: false,
                pricing_actions: [],
                pricing_conditions: [],
            };
            this.newAction();
            this.newCondition();
        }
    }
};
</script>

<style scoped>
.bg-indigo-600 {
  background-color: #2FA3EE;
}

.text-indigo-600 {
  color: #2FA3EE;
}

</style>
